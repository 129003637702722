<template>
  <panel
    :breadcrumbs="breadcrumbs"
    :title="title"
  >
    <v-card>
      <v-list-tile>
        <v-icon left>
          mdi-certificate
        </v-icon>
        <span>AD Contract</span>
        <v-spacer />
        <v-btn
          :href="printContract()"
          target="_blank"
          fab
          small
          color="info"
        >
          <v-icon>mdi-download</v-icon>
        </v-btn>
      </v-list-tile>
    </v-card>
  </panel>
</template>

<script>
import { createGetUrl } from '@/rest'

const pageTitle = 'AD Contract'

export default {
  components: {
    Panel: () => import(/* webpackChunkName: "panel" */ '@/components/Panel'),
  },
  data () {
    return {
      breadcrumbs: [
        {
          text: 'Area Dealer', disabled: false, to: '/areadealer',
        },
        {
          text: pageTitle, disabled: true,
        },
      ],
      title: pageTitle,
      items: {},
      item: {},
      loading: false,
    }
  },

  methods: {
    printContract: function () {
      return process.env.NODE_ENV === 'production'
        ? createGetUrl('https://onesys.onexox.my/form/kontrak-ad-onesys.pdf')
        : createGetUrl('https://onesys.onexox.my/form/kontrak-ad-onesys.pdf')
    },
  },
}
</script>
